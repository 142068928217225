/* eslint-disable react/no-danger */
import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'
import gsap from 'gsap'

import colours from '../styles/colours'
import breaks from '../styles/breakpoints'
import sectionInfo from '../../data/base/sectionInfo'
import { Provider as SectionProvider } from '../components/context/sectionContext'

import MDXLayout from './MDXLayout'
import Layout from '../components/parts/layout'
import Header from '../components/parts/header'
import ScrollToTop from '../components/parts/scrolltop'

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    projectsJson(slug: { eq: $slug }) {
      link
    }
    baseTitle: site {
      siteMetadata {
        title
      }
    }
  }
`

const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  @media ${breaks.mobileUp} {
    width: 80%;
  }
  @media ${breaks.tabletUp} {
    width: 60%;
  }
`

const HeaderWrap = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`

const H1 = styled.h1`
  font-weight: 100;
  margin: 2rem 0;
`

const H2 = styled.h2`
  font-weight: 100;
  padding-top: 1rem;
  margin-top: 3rem;
  border-top: 1px dotted;
  text-align: center;
`

const ImgWrap = styled.div`
  position: relative;
  margin: 2rem 0;
`

const TextWrap = styled.div`
  width: 80%;
  margin: auto;
  font-family: 'Nanum Myeongjo';
  @media ${breaks.mobileUp} {
    width: 80%;
  }
  @media ${breaks.tabletUp} {
    width: 70%;
  }
  @media ${breaks.monitorUp} {
    width: 50%;
  }
`

const IFrame = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
`

function click() {
  gsap.to(window, { scrollTo: '#project', duration: 1.5, ease: 'power2.out' })
}

function Template({ data }) {
  const { body } = data.mdx
  const { frontmatter } = data.mdx
  const { link } = data.projectsJson
  const baseTitle = data.baseTitle.siteMetadata.title

  return (
    <Layout>
      <Helmet>
        <title>{`${baseTitle} | ${frontmatter.title.toLowerCase()}`}</title>
      </Helmet>
      <MDXLayout>
        <SectionProvider sections={sectionInfo} selectedSection="made">
          <Header home={false} />
          <Wrap>
            <HeaderWrap>
              <H1>{frontmatter.title}</H1>
              <ImgWrap onClick={click}>
                <Img
                  style={{
                    width: '100%',
                    height: '100%',
                    boxShadow: `10px 10px 30px ${colours.grey6}`,
                    cursor: 'pointer',
                  }}
                  fluid={frontmatter.image.childImageSharp.fluid}
                />
                <sub>click △ to get to the actual thing</sub>
              </ImgWrap>
            </HeaderWrap>
            <TextWrap>
              <MDXRenderer>{body}</MDXRenderer>
            </TextWrap>
          </Wrap>
          <ScrollToTop />
          <H2>The actual thing</H2>
          <IFrame src={link} id="project" title={frontmatter.slug} />
        </SectionProvider>
      </MDXLayout>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
}

export default Template

// (1) We have two pieces of state: the position (top|bottom) and
// the image (scrollDownImg|scrollUpImg). Both are determined by
// the Scrolltrigger events based on the position of the iframe.
// They also update when the scroll buttons are clicked (as they
// trigger the scroll).
